import React from "react"
import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import ContactForm from "../components/contact-form"
import {
  Section,
  Container,
  AccentText,
  SocialMediaList,
  EmphasisTitle,
  EmphasisDescription,
  EmphasisContainer,
} from "../components/global"
import HeroHeader from "../components/sections/hero-header"

import backShape from "../images/back-shape-1.svg"

const AboutPage = ({ location }) => (
  <Layout
    title="Experts in Microsoft 365 solutions"
    description=".action delivers productivity and automation Microsoft 365 solutions to amplify business of our customers"
    location={location}
  >
    <HeroHeader
      title="About us"
      subTitle="Experts in Microsoft 365 solutions"
      backgroundImage="about-black.png"
      foregroundImage="about-front.png"
      middleImage="middle-shape.png"
    />
    <Section type="accent">
      <EmphasisContainer narrow="true">
        <EmphasisTitle>
          We are <AccentText>.action</AccentText>
        </EmphasisTitle>
        <EmphasisDescription>
          We are a software development company specializing in Microsoft 365
          solutions and services. Our team transforms our own experience,
          knowledge and passion into world-class level products that will make
          our customers and their employees successful.
        </EmphasisDescription>
      </EmphasisContainer>
    </Section>
    <Section type="secondary">
      <AboutContainer narrow="true">
        <h3>Our mission</h3>
        <p>
          We believe in power of automation. Lots of people spend up to 30% of
          their time every day on tedious and simple tasks that can be
          potentially automated. We see our missions in helping companies to
          automate such tasks and processes to let their employees unleash their
          creativity and apply their professional skills.
        </p>
        <h3>Our key values</h3>
        <p>
          Everything we do is based on the following key values:
          <ul>
            <li>
              <strong>People</strong>: we believe that people are key to success
              of every business. We developing talents of our professionals to
              build solutions that will let our customer employees transform
              their creativity and skills into company's success.
            </li>
            <li>
              <strong>Excellence</strong>: what we do is who we are. That is why
              we do everything that we can to deliver the best products and
              provide the best service to our customers.
            </li>
            <li>
              <strong>Integrity</strong>: we are honest, fair and transparent.
              Our customers trust us and we do everything to justify the trust.
            </li>
          </ul>
        </p>
        <div>
          <h4>
            Learn more about our company by following us on our social media
          </h4>
          <SocialMediaList large="true" center="true"></SocialMediaList>
        </div>
      </AboutContainer>
    </Section>
    <Section
      type="accent"
      style={{
        backgroundImage: `url(${backShape})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundSize: "cover",
      }}
    >
      <Container narrow="true" shadow="true">
        <ContactForm title="Whant to know more about us? We would love to answer you!" />
      </Container>
    </Section>
  </Layout>
)

export default AboutPage

const AboutContainer = styled(Container)`
  ${(props) => props.theme.font_size.large};
  /*line-height: 2.5rem;*/

  p {
    ${(props) => props.theme.font_size.large};
    line-height: 2.5rem;
    text-align: justify;

    @media (max-width: ${(props) => props.theme.screen.sm}) {
      ${(props) => props.theme.font_size.regular}
    }
  }

  h3,
  h4 {
    text-align: center;
    ${(props) => props.theme.font_size.large};
  }

  li {
    margin-bottom: 15px;
  }
`
